import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NextImage from "next/image";
const EmptyProduct = ({
  isOnSale
}) => {
  return <Stack paddingY="80px" paddingX="16px" width="100%" direction="column" justifyContent="center" alignItems="center" gap={2} data-sentry-element="Stack" data-sentry-component="EmptyProduct" data-sentry-source-file="empty-product.tsx">
      <Box sx={{
      width: 240,
      height: 240,
      position: "relative",
      overflow: "hidden"
    }} data-sentry-element="Box" data-sentry-source-file="empty-product.tsx">
        <NextImage src="/assets/images/empty-sale.png" width={240} height={240} alt="Empty flash sale products" data-sentry-element="NextImage" data-sentry-source-file="empty-product.tsx" />
      </Box>
      {isOnSale ? <>
          <Typography variant="body1" sx={{
        textAlign: "center",
        fontWeight: 700
      }}>
            Chương trình Sale off sắp diễn ra!
          </Typography>
          <Typography variant="body2" sx={{
        textAlign: "center"
      }}>
            Chương trình Sale off của The C.I.U sẽ diễn ra trong thời gian sắp
            tới, bạn cùng theo dõi và đón mua nhé!
          </Typography>
        </> : <Typography variant="body2" sx={{
      textAlign: "center"
    }}>
          Sản phẩm sắp được cập nhật, bạn cùng chờ xem nhé!
        </Typography>}
    </Stack>;
};
export default EmptyProduct;
"use client";

import Box from "@mui/material/Box";
import { Icons, ProductCard } from "@src/components";
import { useCallback, useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const ButtonNavigation = ({
  children,
  onClick,
  disabled
}) => {
  return <Box sx={{
    backgroundColor: "#7D7D7D",
    width: {
      xs: "24px",
      sm: "32px",
      lg: "48px"
    },
    height: {
      xs: "24px",
      sm: "32px",
      lg: "48px"
    },
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    svg: {
      color: "white"
    },
    "&.disabled": {
      backgroundColor: "#ECECEC",
      cursor: "not-allowed",
      svg: {
        color: "#B9B9B9"
      }
    }
  }} onClick={onClick} className={disabled ? "disabled" : ""} data-sentry-element="Box" data-sentry-component="ButtonNavigation" data-sentry-source-file="carousel-product-sale.tsx">
      {children}
    </Box>;
};
const CarouselProductSale = props => {
  const {
    data = []
  } = props || {};
  const sliderRef = useRef(null);
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.swiper.on("slideChange", () => {
        setIsEnd(sliderRef.current?.swiper?.isEnd);
        setIsBeginning(sliderRef.current?.swiper?.isBeginning);
      });
    }
  }, [sliderRef, data]);
  const handlePrev = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  }, []);
  const handleNext = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  }, []);
  return <Box position="relative" width="100%" margin="auto" data-sentry-element="Box" data-sentry-component="CarouselProductSale" data-sentry-source-file="carousel-product-sale.tsx">
      <Box sx={{
      minHeight: "318px",
      margin: "auto"
    }} position="relative" data-sentry-element="Box" data-sentry-source-file="carousel-product-sale.tsx">
        <Swiper ref={sliderRef} breakpoints={{
        200: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 4
        }
      }} spaceBetween={24} loop={false} pagination={false} modules={[Pagination, Navigation]} className="mySwiper" data-sentry-element="Swiper" data-sentry-source-file="carousel-product-sale.tsx">
          {data.map(item => <SwiperSlide key={item.id}>
              <ProductCard product={item} />
            </SwiperSlide>)}
        </Swiper>
      </Box>

      <Box sx={{
      display: "flex",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      justifyContent: "space-between",
      width: {
        xs: "calc(100% + 36px)",
        lg: "calc(100% + 116px)"
      },
      zIndex: 10
    }} data-sentry-element="Box" data-sentry-source-file="carousel-product-sale.tsx">
        <ButtonNavigation disabled={isBeginning} onClick={handlePrev} data-sentry-element="ButtonNavigation" data-sentry-source-file="carousel-product-sale.tsx">
          <Icons name="caret-left" data-sentry-element="Icons" data-sentry-source-file="carousel-product-sale.tsx" />
        </ButtonNavigation>
        <ButtonNavigation disabled={isEnd} onClick={handleNext} data-sentry-element="ButtonNavigation" data-sentry-source-file="carousel-product-sale.tsx">
          <Icons name="caret-right" data-sentry-element="Icons" data-sentry-source-file="carousel-product-sale.tsx" />
        </ButtonNavigation>
      </Box>
    </Box>;
};
export default CarouselProductSale;
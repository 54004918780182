"use client";

import useQuery from "@src/hooks/use-query";
import { useLayoutContext } from "@src/layouts/provider";
import HomeSectionContainer from "@src/sections/home/home-section-container";
import CarouselProductSale from "./carousel-product-sale";
import NotStartFlashSale from "./not-start-flash-sale";
import TitleFlashSale from "./title-flash-sale";
const FlashSale = () => {
  const {
    config
  } = useLayoutContext();
  const {
    data
  } = useQuery({
    url: `/products/paginate?pageSize=20&type=flash_sale`,
    enabled: !!config?.flash_sale?.isGoingOn
  });
  if (!config?.flash_sale?.isActive) {
    return null;
  }
  const renderContent = () => {
    if (config?.flash_sale?.isNotStarted) {
      return <NotStartFlashSale />;
    }
    if (config?.flash_sale?.isGoingOn) {
      return <CarouselProductSale data={data || []} />;
    }
    return null;
  };
  return <HomeSectionContainer data-sentry-element="HomeSectionContainer" data-sentry-component="FlashSale" data-sentry-source-file="index.tsx">
      <TitleFlashSale data-sentry-element="TitleFlashSale" data-sentry-source-file="index.tsx" />
      {renderContent()}
    </HomeSectionContainer>;
};
export default FlashSale;
"use client";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { Icons, NextImage } from "@src/components";
import { useResponsive } from "@src/hooks";
import type { Banner } from "@src/types/home";
import { LOCAL_KEYS, LocalStorage } from "@src/utils/storages";
import { useEffect, useState } from "react";
import Link from "next/link";
const ModalPopup = (props: {
  data: Banner;
}) => {
  const {
    data
  } = props || {};
  const {
    isMobile
  } = useResponsive();
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const localData = LocalStorage.get(LOCAL_KEYS.POPUP_BANNER_ID) || {
      id: null,
      timestamp: null
    };
    const haftDay = 12 * 60 * 60 * 1000;
    const localTimestamp = new Date().getTime() - (localData?.timestamp || 0);
    if (data?.mobile_image || data?.desktop_image) {
      if (data?.id !== localData?.id || localTimestamp > haftDay) {
        setOpen(true);
        LocalStorage.set(LOCAL_KEYS.POPUP_BANNER_ID, {
          id: data?.id,
          timestamp: new Date().getTime()
        });
      }
    }
  }, [data]);
  const onClose = () => {
    setOpen(false);
  };
  return <Dialog maxWidth="md" open={open} onClose={onClose} aria-labelledby="popup-home" aria-describedby="popup-home" sx={{
    "& .MuiPaper-root": {
      backgroundColor: "transparent !important",
      boxShadow: "none",
      margin: "16px !important"
    }
  }} data-sentry-element="Dialog" data-sentry-component="ModalPopup" data-sentry-source-file="modal-popup.tsx">
      <Box sx={{
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
      zIndex: 9999,
      marginBottom: "8px",
      visibility: loaded ? "visible" : "hidden"
    }} data-sentry-element="Box" data-sentry-source-file="modal-popup.tsx">
        <Icons sx={{
        backgroundColor: "white",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "white",
          opacity: 0.8
        }
      }} name="close" type="button" onClick={onClose} data-sentry-element="Icons" data-sentry-source-file="modal-popup.tsx" />
      </Box>
      <Link href={data?.url} passHref data-sentry-element="Link" data-sentry-source-file="modal-popup.tsx">
        <NextImage alt={data?.title} src={isMobile ? data?.mobile_image : data?.desktop_image} sx={{
        width: "100%",
        height: "100%",
        "& img": {
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "100%",
          maxHeight: "80vh",
          position: "relative !important"
        }
      }} loading="eager" onLoad={() => {
        setLoaded(true);
      }} data-sentry-element="NextImage" data-sentry-source-file="modal-popup.tsx" />
      </Link>
    </Dialog>;
};
export default ModalPopup;
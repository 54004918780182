"use client";

import { SectionContainer } from "@src/components";
import Container from "@mui/material/Container";
const HomeSectionContainer = ({
  children,
  sx = {},
  sxWrapper = {}
}) => <SectionContainer sxWrapper={{
  backgroundColor: "transparent",
  ...sxWrapper
}} sx={{
  backgroundColor: "white",
  borderRadius: "16px",
  maxWidth: {
    xs: "100%",
    md: "calc(100% - 80px)"
  },
  margin: {
    xs: "auto 0px",
    md: "auto 48px"
  },
  padding: {
    xs: "8px",
    md: "24px 16px"
  },
  ...sx
}} maxWidth={false} data-sentry-element="SectionContainer" data-sentry-component="HomeSectionContainer" data-sentry-source-file="home-section-container.tsx">
    <Container width="100%" maxWidth="xl" sx={{
    paddingX: {
      xs: 0,
      md: 4
    }
  }} data-sentry-element="Container" data-sentry-source-file="home-section-container.tsx">
      {children}
    </Container>
  </SectionContainer>;
export default HomeSectionContainer;
"use client";

import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Toastify } from "@src/components";
import VoucherItem from "@src/components/VoucherItem";
import useMutation from "@src/hooks/use-mutation";
import { useLayoutContext } from "@src/layouts/provider";
import CartVoucherModel from "@src/models/cartVoucher";
import HomeSectionContainer from "@src/sections/home/home-section-container";
import { useEffect, useState } from "react";
const WrapperTabs = styled(Tabs)({
  "& .MuiTabs-scroller::-webkit-scrollbar": {
    display: "none"
  },
  "& .MuiTabScrollButton-root": {
    width: "20px",
    height: "20px",
    backgroundColor: "#7D7D7D",
    borderRadius: "50%",
    margin: "auto 2px",
    "& svg": {
      color: "white",
      fontSize: "18px"
    }
  }
});
const VoucherComponent = ({
  data = []
}) => {
  const {
    onSaleColor
  } = useLayoutContext();
  const {
    mutation,
    loading: isLoadingSave
  } = useMutation();
  const [voucherSelected, setVoucherSelected] = useState(null);
  const [voucherList, setVoucherList] = useState([]);
  useEffect(() => {
    setVoucherList(data);
  }, [data]);
  const handleAddVoucher = async (voucher: CartVoucherModel) => {
    if (voucher?.id) {
      setVoucherSelected(voucher);
      await mutation({
        url: `/auth/vouchers/${voucher.id}/save`,
        method: "POST",
        onSuccess: res => {
          if (res) {
            Toastify.success(res?.message || "Đã lưu voucher");
            const newVoucherList = voucherList.map(item => {
              if (item.id === voucher.id) {
                return {
                  ...item,
                  saved: true
                };
              }
              return item;
            });
            setVoucherList(newVoucherList);
            setVoucherSelected(null);
          }
        },
        onFailed: () => {
          setVoucherSelected(null);
        }
      });
    }
  };
  if (!voucherList || voucherList.length === 0) {
    return null;
  }
  return <HomeSectionContainer data-sentry-element="HomeSectionContainer" data-sentry-component="VoucherComponent" data-sentry-source-file="voucher.tsx">
      <Typography variant="h3" component="h3" sx={{
      fontSize: {
        xs: "16px",
        md: "24px"
      },
      fontWeight: 700
    }} data-sentry-element="Typography" data-sentry-source-file="voucher.tsx">
        THE C.I.U VOUCHER
      </Typography>
      <Stack mt={{
      xs: 1,
      md: 2
    }} mb={{
      xs: 1,
      md: 0
    }} spacing={2} sx={{
      // hidden scrollbar
      overflow: "hidden",
      width: "calc(100% + 40px)",
      marginLeft: "-20px"
    }} data-sentry-element="Stack" data-sentry-source-file="voucher.tsx">
        <WrapperTabs variant="scrollable" scrollButtons visibleScrollbar={true} aria-label="scrollable auto tabs example" allowScrollButtonsMobile={true} data-sentry-element="WrapperTabs" data-sentry-source-file="voucher.tsx">
          {voucherList.map((item: CartVoucherModel) => <VoucherItem key={item.code} type="card" onSaleColor={onSaleColor} data={item} loading={isLoadingSave && item.id === voucherSelected?.id} onAddVoucher={handleAddVoucher} />)}
        </WrapperTabs>
      </Stack>
    </HomeSectionContainer>;
};
export default VoucherComponent;